<template>
  <div class="shop_list">
    <back />
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="shopName" placeholder="请输入商超名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="shopName" label="商超名称"></el-table-column>
          <el-table-column show-overflow-tooltip prop="schoolName" label="关联学校"></el-table-column>
          <el-table-column show-overflow-tooltip prop="contactMan" label="联系人"></el-table-column>
          <el-table-column show-overflow-tooltip prop="contactTel" label="联系电话"></el-table-column>
          <el-table-column show-overflow-tooltip prop="address" label="地址">
            <template slot-scope="scope">
              <span>{{ scope.row.province + scope.row.city + scope.row.area + scope.row.address }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      shopName: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 列表
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.selectShopInfoListByName, {
          params: {
            supplierId: this.$store.state.userInfo.supplierId,
            shopName: this.shopName,
            cityCode: this.$route.query.cityCode,
            provinceCode: this.$route.query.provinceCode,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.shop_list {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;

      ::v-deep .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-button {
      margin-left: 20px;
    }
  }

  .disabled_user_account {
    width: 255px;
    color: #c0c4cc;
    padding: 0 15px;
    border-radius: 10px;
    background-color: #f5f7fa;
    box-sizing: border-box;
    cursor: not-allowed;
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dialoglabel {
    font-size: 16px;
    margin-right: 20px;
  }
}
</style>
